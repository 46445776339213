import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import dayjs from 'dayjs';

export const validateForm = (
  form: UntypedFormGroup,
  ignoreupdateValueAndValidity = false
) => {
  // tslint:disable-next-line:forin
  for (const controlKey in form.controls) {
    if (form.controls[controlKey] instanceof UntypedFormArray) {
      for (const formGroup of (form.controls[controlKey] as UntypedFormArray)
        .controls) {
        // tslint:disable-next-line:forin
        for (const innerControlKey in (formGroup as UntypedFormGroup)
          .controls) {
          // tslint:disable-next-line:no-unused-expression
          (formGroup as UntypedFormGroup).controls[
            innerControlKey
          ].markAsTouched();
        }
      }
      continue;
    }
    form.controls[controlKey].markAsTouched();
    if (!ignoreupdateValueAndValidity) {
      form.controls[controlKey].updateValueAndValidity();
    }
  }
};

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function replaceRouteParams(str: string, obj: any) {
  return str.replace(/:([^/]+)/g, function (match, p1) {
    return obj[p1];
  });
}

export function ObjectIsEmpty(object) {
  for (const prop in object) {
    if (Object.hasOwn(object, prop)) {
      return false;
    }
  }

  return true;
}

export function isObject(object) {
  return typeof object === 'object' && object !== null;
}

export function truncateString(inputString: string, maxLength: number): string {
  if (!inputString) return;
  return inputString?.length > maxLength
    ? inputString?.substring(0, maxLength) + '...'
    : inputString;
}

export function isNearExpiration(expirationDate: Date): boolean {
  const currentDate = dayjs();
  const oneMonthFromNow = currentDate?.add(1, 'month');

  const expiryDate = dayjs(expirationDate);
  return (
    (expiryDate?.isAfter(currentDate) &&
      expiryDate?.isBefore(oneMonthFromNow)) ||
    expiryDate?.isBefore(currentDate) ||
    expiryDate?.isSame(currentDate)
  );
}

export function ConvertUnixToDate(unixTimestamp: number) {
  return unixTimestamp
    ? dayjs.unix(unixTimestamp)?.isValid()
      ? dayjs.unix(unixTimestamp).toDate()
      : ''
    : '';
}

export function HasCardExpired(expirationDate: Date): boolean {
  const currentDate = dayjs();
  const expiryDate = dayjs(expirationDate);

  // if true then card is invalid
  return expiryDate?.isBefore(currentDate);
}

export function calculateTotalAmount(
  amount: number,
  multiplier: number = 0.04
) {
  const calculatedPercentage = Number(amount) * multiplier;
  const totalAmount = (Number(amount) + calculatedPercentage)?.toString();

  const formattedAmount = parseFloat(totalAmount).toFixed(2);
  return Number(formattedAmount);
}
