// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  showLogs: false,
  hmr: false,
  whitelable: false,
  apiUrl: 'http://localhost:4000',
  webApiBaseUrl: 'https://webapi-gw-dev',
  paymentApiBaseUrl: 'https://webapi-gw-dev.gettrx.com/internal/payments/',
  iamApiBaseUrl: 'https://webapi-gw-dev.gettrx.com/internal/iam/',
  apiBaseUrl: 'https://webapi-gw-dev.gettrx.com/',
  apiEntityUrl: 'https://webapi-gw-dev.gettrx.com/',
  imgFileBaseUrl: 'https://d16ah8wr3b1l39.cloudfront.net',
  mock2Url: 'https://2a7542f0-5478-4a2b-afd6-b1a9ab8143fa.mock.pstmn.io',
  mock3Url: 'https://3a85f247-5636-4ba0-9513-64322fb3b032.mock.pstmn.io/',
  mock4Url: 'https://60439102-cca5-4b8c-bb09-175202db7e56.mock.pstmn.io',
  mock5Url: 'https://1130f000-1c7e-4ab4-ac56-dcc5a0ba2397.mock.pstmn.io/',
  mock6Url: 'https://23d8a5ac-9df7-4fa9-94db-3d90e5e59f18.mock.pstmn.io/',
  onboardApi: 'https://f7e9b42f-5299-42b9-84c5-1d67d1c6f47a.mock.pstmn.io',
  mockApi: 'https://d4b8f76e-e893-4bd9-be97-9892ce821694.mock.pstmn.io/',
  paymentMockUrl: 'https://5a30f04b-fc39-411a-95f5-715bd5bffac2.mock.pstmn.io/',
  recaptchaSiteKey: '6LfSCzYpAAAAAHaqwAn7hc-Rosu1ShgofiQq89kc',
  sdkScriptUrl: 'https://js-dev.gettrx.com/sdk/js/payments/v1.0/sdk.js',
  sdkBaseSubdomain: 'https://js-dev',
  sdkPublicKey:
    'pk_Tg1a0Jp1qy2RNy4gv7tVM4Bp5lCXZ-lk6pIyWzdGkkKFkR7EBCIkrJhVUN54H7lE',
  esRUM_ServiceURL:
    'https://a51f1c5c8dff4deba8a5b0e39447fe58.apm.us-east-2.aws.elastic-cloud.com/',
  s3BucketUrl: 'https://iso-business-images.s3.amazonaws.com/',
  processingFeeAccount: 'acm_661efbfab86f650001455fdb',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
